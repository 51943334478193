.input-group {
    @apply relative flex flex-wrap items-stretch;

    & > .form-control {
        @apply flex-1 focus:z-[3];

        &:not(:first-child) {
            @apply rounded-l-none;
        }

        &:not(:last-child) {
            @apply rounded-r-none;
        }
    }

    & > .select-control {
        &:not(:first-child) {
            & button.form-control {
                @apply rounded-l-none;
            }
        }

        &:not(:last-child) {
            & button.form-control {
                @apply rounded-r-none;
            }
        }
    }

    & .form-control {
        @apply  focus:!ring-0;
    }

    .input-group-text {
        @apply flex items-center justify-center border border-gray-200 bg-[#f0f3f5] px-3 py-1.5 rounded;
    }

    .input-group-prepend {
        @apply flex -mr-px;

        .input-group-text,
        .btn {
            @apply rounded-r-none;
        }
    }

    .input-group-append {
        @apply flex -ml-px;

        .input-group-text,
        .btn {
            @apply rounded-l-none;
        }
    }
}

.input-group-sm {
    .input-group-text {
        @apply px-2 py-1 text-[0.76562rem] leading-normal rounded-[0.1875rem];
    }

    & input.form-control:not([type="checkbox"]),
    & textarea.form-control,
    & select.form-control,
    & button.form-control {
        @apply px-2 py-0.5;
    }

    .btn {
        @apply px-2 py-1 text-[0.76562rem] leading-normal rounded-[0.1875rem];
    }
}