.nav {
    .nav-link {
        @apply flex px-4 py-2 no-underline cursor-pointer leading-tight items-center;

        & .nav-error-count {
            @apply inline-flex items-center justify-center rounded-full ml-auto pl-5 pr-1.5 py-0.5 font-bold !leading-none whitespace-nowrap text-[75%]
                 bg-red-500 text-white;

            &:before {
                @apply inline-block font-black content-["\f06a"] -ml-4 w-4;
                font-family: 'Font Awesome 5 Free';
            }
        }

        &.active {
            @apply bg-primary-600 text-white;
        }

    }
}