.app {
    @apply bg-[#eef1f4];
}

.app-content {
    @apply flex flex-col min-h-screen;
}

.app-header {
    @apply fixed w-full flex items-center h-[55px] p-2 bg-white border-b border-gray-200 z-[1020];

    .dropdown-menu[data-bs-popper].dropdown-menu-end {
        @apply left-auto right-0;
    }
}

.app-body {
    @apply mt-[55px] flex flex-col flex-grow;

    .sidebar {
        @apply fixed w-13 h-full z-[1019];

        &.sidebar-expanded {
            @apply w-50;
        }
    }

    .main {
        @apply ml-13 flex flex-col px-4 flex-grow;
    }
}

.app[data-app-sidebar-value="expanded"] .app-body .main {
    @apply ml-50;
}

.turbo-progress-bar {
    @apply h-[3px] bg-primary-600;
}

.row {
    /* @apply flex flex-col lg:flex-row gap-6; */
    @apply grid grid-cols-12 gap-4;
}