.scrolltable {
    @apply relative !flex flex-col flex-grow mb-4;

    & .scrolltable-outer {
        @apply w-full max-h-full !mb-0 overflow-y-auto overscroll-y-none;
    }

    & table {
        @apply max-w-none bg-white;

        & thead {
            & th {
                @apply sticky top-0 z-10;
                
                &::after {
                    @apply absolute left-0 right-0 -bottom-px h-px bg-gray-200;
                    
                    content: '';
                }
            }

        }
    }

    &.flex-height {
        @apply md:min-h-0 md:basis-0;
    }
}
.iOS .scrolltable {
    & .scrolltable-outer {
        position: absolute;
        max-height: 100%;
    }
}