.dropdown-toggle {
    @apply whitespace-nowrap;

    &::after {
        @apply inline-block ml-[.255em] content-[""];

        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
        vertical-align: 0.225em;
      }
}

.dropdown-toggle-split {
    @apply px-[0.5625rem] after:ml-0;
}


.dropup {
    .dropdown-toggle::after {
        border-top: 0;
        border-right: .3em solid transparent;
        border-bottom: .3em solid;
        border-left: .3em solid transparent;
    }
}

.dropdown,
.dropdown-center {
    @apply relative;
}

.dropdown-menu {
    @apply absolute hidden z-[1000] min-w-[10rem] m-0 p-0
          text-left bg-white border border-gray-200 rounded list-none overflow-hidden;

    box-shadow: 0px 1px 16px rgba(0,0,0,0.15);
  
    &[data-bs-popper] {
        @apply top-full left-0 mt-1;
    }

    .popper-arrow {
        @apply w-2 h-2 -z-[1] absolute;

        &::before {
            @apply w-2 h-2 -z-[1] absolute bg-white border-gray-200 rotate-45;
            
            content: '';
        }
    }

    &[data-popper-placement^='top'] > .popper-arrow {
        @apply -bottom-[5px];

        &::before {
            @apply border-r border-b;
        }
    }

    &[data-popper-placement^='bottom'] > .popper-arrow {
        @apply -top-[5px];

        &::before {
            @apply border-l border-t;
        }
    }

    &[data-popper-placement^='left'] > .popper-arrow {
        @apply -right-[5px];

        &::before {
            @apply border-r border-t;
        }
    }

    &[data-popper-placement^='right'] > .popper-arrow {
        @apply -left-[5px];

        &::before {
            @apply border-l border-b;
        }
    }

    &.show {
        @apply block;
    }

    .dropdown-item {
        @apply block w-full py-2 px-6 bg-transparent whitespace-nowrap clear-both border-0 no-underline
               text-gray-900 font-normal hover:bg-primary-600/25;
        
        text-align: inherit;

        &.active {
            @apply bg-primary-600/25;
        }

        & > i {
            @apply inline-block w-5 mr-2.5 -ml-2.5 text-center;
        }

        &:disabled,
        &.disabled {
            @apply opacity-50 cursor-default pointer-events-none;
        }
    }

    .dropdown-divider {
        @apply h-0 overflow-hidden border-t border-gray-100;
      }
}

.dropdown-menu-end {
    --bs-position: end;
}