
@import "tailwindcss/base";
@import "tailwindcss/components";

@import "./base.css";
@import "./layout.css";

@import "./components/alert.css";
@import "./components/badge.css";
@import "./components/breadcrumb.css";
@import "./components/button.css";
@import "./components/card.css";
@import "./components/charts.css";
@import "./components/collapse.css";
@import "./components/colorpicker.css";
@import "./components/datepicker.css";
@import "./components/dropdown.css";
@import "./components/form.css";
@import "./components/input-group.css";
@import "./components/nav.css";
@import "./components/quick-stats.css";
@import "./components/richtext.css";
@import "./components/modal.css";
@import "./components/pagination.css";
@import "./components/popover.css";
@import "./components/select.css";
@import "./components/sidebar.css";
@import "./components/survey.css";
@import "./components/table.css";
@import "./components/scrolltable.css";
@import "./components/timeline.css";

@import "tailwindcss/utilities";
