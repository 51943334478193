.modal {
    @apply hidden fixed top-0 left-0 w-full h-full z-[1055] outline-0 overflow-x-hidden overflow-y-auto;

    .modal-dialog {
        @apply max-w-[500px] relative flex mx-auto py-14 max-h-full pointer-events-none;

        transition:
            opacity 200ms linear,
            transform 300ms ease-out;

        .modal-content {
            @apply relative w-full pointer-events-auto overflow-hidden rounded text-left flex flex-col
                 bg-white bg-clip-padding;

            box-shadow: 0px 1px 16px rgba(0,0,0,0.15);

            form {
                @apply w-full h-full flex flex-col;
            }
        }

        .modal-header {
            @apply flex items-center px-6 py-4 relative flex-grow-0 flex-shrink-0 bg-gray-400 text-white;

            .modal-title {
                @apply leading-normal;
            }

            .btn-icon {
                @apply border-transparent ml-auto -mt-1 -mb-1;
            }
        }

        .modal-body {
            @apply px-6 py-6 flex-auto overflow-y-scroll;
        }

        .modal-footer {
            @apply text-sm leading-6 font-normal px-6 pt-3 pb-6 flex flex-row-reverse flex-grow-0 flex-shrink-0 gap-3;
        }

        &.modal-sm {
            @apply max-w-[500px];
        }
    
        &.modal-lg {
            @apply max-w-[800px];
        }
    
        &.modal-xl {
            @apply max-w-[1140px];
        }

        .modal-success .modal-header {
            @apply bg-green-500 text-white;
        }

        .modal-danger .modal-header {
            @apply bg-red-500 text-white;
        }

        .modal-indigo .modal-header {
            @apply bg-indigo-500 text-white;
        }

        &.modal-sidepanel {
            @apply p-0 mr-0 h-full;

            .modal-content {
                @apply rounded-none;
            }

            .modal-header {
                @apply px-8;
            }

            .modal-body {
                @apply px-8;
            }

            .modal-footer {
                @apply bg-gray-100 border-t border-gray-200 flex-row px-8 pt-6;
            }
        }
    }

    &.fade .modal-dialog {
        @apply opacity-0;

        transform: scale(0.85);

        &.modal-sidepanel {
            @apply opacity-100;
            transform: translate3d(100%, 0, 0);
        }
    }

    &.show .modal-dialog {
        @apply opacity-100;
        transform: scale(1);

        &.modal-sidepanel {
            @apply opacity-100;
            transform: translate3d(0, 0, 0)
        }
    }

    .form-horizontal {
        @apply !mb-0;
    }
}

.modal-backdrop {
    @apply fixed top-0 left-0 w-full h-full bg-gray-800 z-[1050];
    transition: background-color 100ms linear;

    &.fade {
        @apply bg-gray-800/0;
    }

    &.show {
        @apply bg-gray-800/80 backdrop-blur-sm;
    }
}