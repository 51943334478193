.table {
    @apply w-full;

    & td {
        @apply align-middle px-[0.6rem] py-[0.4rem] border-t border-gray-200;
    }

    & td {
        &.td-btn,
        &.td-icon {
            @apply py-0 align-middle whitespace-nowrap w-px;
        }
    }

    & thead {
        & th {
            @apply bg-white text-gray-600 px-2.5 py-4 uppercase text-[0.7rem] text-left border-t-0;

            & .list-order-header {
                @apply block text-gray-600 whitespace-nowrap;
                
                
                &.active {
                    @apply text-primary-600;

                    & i {
                        @apply text-primary-600;
                    }
                }
                
                & i {
                    @apply text-gray-300 w-[0.45rem] inline-block ml-1.5;
                }
                
                &:hover i {
                    @apply text-primary-600;
                }
            }

            &.list-select-header {
                padding-top: calc(0.8rem - 1px);
                padding-bottom: calc(0.8rem - 1px);

                & .select-outer {
                    @apply relative;

                    & select {
                        @apply inline-block w-full text-gray-600 whitespace-nowrap border border-gray-100 
                               bg-gray-50/75 rounded-[0.1875rem] leading-normal outline-0 ring-0 appearance-none uppercase
                               overflow-ellipsis font-bold no-underline py-[0.2rem] pr-[1.2rem] pl-[0.4rem];

                               font-size: inherit;
                               background-position: right 0.25rem center;
                               background-size: 0.75rem 0.75rem;

                        
                        & option {
                            @apply w-auto font-normal text-sm/normal;
                        }
                        
                    }

                    /* &::after {
                        @apply inline-block absolute right-2 ml-[0.255rem] align-[0.255rem] content-[""] border-b-0;
                    
                        top: calc(0.75rem - 1px);
                        border-top: .3em solid;
                        border-right: .3em solid transparent;
                        border-left: .3em solid transparent;
                    } */
                }
            }
        }
    }

    & tbody {
        & tr:hover {
            & td {
                @apply bg-[#f0fdff];
            }
        }
    }

    & tfoot {
        & tr {
            @apply bg-[#f0f3f5];
        }

        & td {
            @apply text-gray-600 font-bold;
        }
    }

    &.table-grid {
        @apply border-b border-gray-200;

        & td,
        & thead th {
            @apply px-5 first:pl-2.5 last:pr-2.5;
        }

        & td {
            @apply border-r last:border-r-0 border-gray-200;
        }


    }
}

td.td-shrink {
    @apply whitespace-nowrap w-px;
}