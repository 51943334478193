.popover {
    @apply block absolute top-0 left-0 max-w-xs text-sm/normal bg-white border border-black/20 rounded z-[1060] bg-clip-padding;
    
    .popover-arrow {
        @apply block absolute w-4 h-2;

        &::before,
        &::after {
            @apply block absolute content-[""] border-transparent
        }
    }
        
    .popover-header {
        @apply px-3 py-2 mb-1 text-sm/normal empty:hidden;
    }
    
    .popover-body {
        @apply px-3.5 py-2.5 text-gray-900;
    }
}

.bs-popover-top > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    @apply
        before:bottom-0 before:border-t-black/20 before:border-[0.5rem] before:border-b-0
        after:bottom-px after:border-t-white after:border-[0.5rem] after:border-b-0;

    bottom: calc(-0.5rem - 1px);
}

.bs-popover-right > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    @apply 
        w-2 h-4 
        before:left-0 before:border-r-black/20 before:border-[0.5rem] before:border-l-0
        after:left-px after:border-r-white after:border-[0.5rem] after:border-l-0;
    
    left: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    @apply
        before:top-0 before:border-b-black/20 before:border-[0.5rem] before:border-t-0
        after:top-px after:border-b-white after:border-[0.5rem] after:border-t-0;

    top: calc(-0.5rem - 1px);
}

.bs-popover-left > .popover-arrow, 
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    @apply 
        w-2 h-4 
        before:right-0 before:border-l-black/20 before:border-[0.5rem] before:border-r-0
        after:right-px after:border-l-white after:border-[0.5rem] after:border-r-0;

    right: calc(-0.5rem - 1px);
}
