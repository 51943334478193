.card {
    @apply flex flex-col min-w-0 bg-white border border-gray-50 rounded;

    .card-block {
        @apply p-5 flex-auto;
    }

    .form-group .col-form-label {
        @apply font-light;
    }
}

.card-subtitle {
    @apply -mt-1.5;
}