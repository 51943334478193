.__form-control-sm {
    @apply px-2 py-1 text-[0.76562rem] leading-normal rounded-[0.1875rem];
}

.form-control {
    @apply border border-transparent text-gray-900 text-sm leading-[1.6] focus:ring-0 resize-none;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea {
    @apply px-3 py-1.5;
}

input[type=checkbox].hidden-input,
input[type=radio].hidden-input {
    @apply hidden;
}

input[type=checkbox]:not(.hidden-input),
input[type=radio]:not(.hidden-input) {
    @apply hidden;

    & + label:before {
        @apply inline-block text-gray-300 align-text-bottom content-["\f0c8"] text-base/4 font-normal cursor-pointer;
        
        font-family: 'Font Awesome 5 Free';
    }

    &.is-invalid + label:before {
        @apply !text-red-500;
    }

    &:checked + label:before {
        @apply text-primary-600 font-black content-["\f14a"];
    }

    &:disabled + label:before { 
        @apply text-gray-200;
    }
}

input[type=radio]:not(.hidden-input) {
    & + label:before {
        @apply content-["\f111"] mr-2;
    }

    &:checked + label:before {
        @apply font-black content-["\f192"];
    }

    &:disabled + label:before { 
        @apply font-black content-["\f111"];
    }

    &:disabled:checked + label:before { 
        @apply content-["\f192"];
    }
} 

input[type=checkbox]:not(.hidden-input) {
    &:disabled + label:before { 
        @apply font-black content-["\f0c8"];
    }

    &:disabled:checked + label:before { 
        @apply content-["\f14a"];
    }
}

input.form-control:not([type="checkbox"]),
textarea.form-control,
select.form-control,
button.form-control {
    @apply border-gray-200 focus:border-primary-500 focus:ring-1 ring-inset focus:ring-primary-50 placeholder:text-gray-500
           w-full rounded px-3 py-1.5;

    transition: 
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    /* &[readonly] {
        @apply bg-gray-100 text-black hover:shadow-none focus:border-transparent;

        background-image: repeating-linear-gradient(90deg, theme(colors.gray.500) 0, theme(colors.gray.500) 8px, transparent 8px, transparent 12px);
    } */

    &.is-invalid {
        @apply border-red-500 focus:ring-red-100 pr-8;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23df475b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23df475b' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 1rem 1rem;
    }

    &:disabled {
        @apply bg-gray-200 text-gray-600;
    }

    &.form-control-sm {
        @apply __form-control-sm;
    }
}

select.form-control,
button.form-control {
    &:not([multiple]) {
        @apply !pr-7 text-ellipsis;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2329363d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 8px 10px;

        &:disabled {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23869fac' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
        }
    }

    &[multiple] {
        @apply py-2;

        option {
            @apply overflow-hidden text-ellipsis px-0;

            &:checked {
                @apply bg-primary-600/50 outline-0;
            }
        }

        &:focus option:checked {
            @apply bg-primary-600 bg-gradient-to-r from-primary-600 to-primary-600 text-white;
        }
    }
}

.select-control {
    @apply flex flex-1;
    
    & > div {
        @apply flex-grow;
    }

    &.select-control-sm button.form-control {
        @apply __form-control-sm;
    }
}

.col-form-label {
    @apply leading-normal font-medium;

    padding-top: calc(theme(spacing[1.5]) + 1px);
    padding-bottom: calc(theme(spacing[1.5]) + 1px);
}

.form-text {
    @apply block mt-1;
}

.form-field-help {
    @apply pl-[0.3rem];
}

.form-group {
    @apply mb-4 last:mb-0;
}

.form-row {
    @apply grid grid-cols-12 md:gap-2;
}

.form-subtitle {
    @apply text-gray-700 mb-4 md:mb-6;   
}

.form-section-title {
    @apply text-[0.925rem] font-medium text-gray-600 mb-2 md:mb-3;
}

.form-check-inline {
    @apply inline-flex items-center pl-0 mr-3;
}

.form-horizontal {
    /* @apply mb-4; */

    .card:not(:last-child) {
        @apply mb-4;
    }

    .form-row {
        .form-choices-wrap {
            @apply pt-2;
        }
    } 
}

.form-vertical {
    .form-group {
        &.form-row {
            @apply !gap-0;

            & > * {
                @apply !col-span-12;
            }

            & .form-check-label {
                @apply ml-2;
            }
        }
    }
}

.form-choices-wrap {
    & input[type=checkbox] + label:before { 
        @apply mr-2;
    }

    & > div:not(.form-check-inline):not(:last-child) {
        @apply mb-2;
    }

    & label.form-check-label {
        @apply cursor-pointer;
    }
}

.is-required label:not(.form-check-label):not(.form-choice)::after {
    @apply text-[0.5rem] font-black text-primary-600 align-super relative -top-px content-["\f069"];
    
    font-family: 'Font Awesome 5 Free';
}

.invalid-feedback {
    @apply w-full mt-0.5 text-red-500;
    
    .errorlist li {
        @apply pl-5;

        &:before {
            @apply inline-block font-black content-["\f06a"] -ml-4 w-4;
            font-family: 'Font Awesome 5 Free';
        }
    }
}

.form-sm {
    & input.form-control:not([type="checkbox"]),
    & textarea.form-control,
    & select.form-control,
    & button.form-control {
        @apply __form-control-sm;
    }

    .form-group {
        @apply mb-6 last:mb-0;
    }

    .col-form-label {
        @apply py-0.5;
    }

    .form-choices-wrap > div:not(:last-child) {
        @apply mb-1;
    }

    .form-group {
        @apply mb-6 last:mb-0;
    }
}

.form-submit-button {
    @apply pb-17;
}

.form-controls {
    @apply flex items-center justify-center fixed h-17 bottom-0 left-0 right-0 ml-13 px-4 bg-white border-t border-gray-200;

    & > button {
        @apply min-w-[7rem];
    }

    .btn-group > button:not(.dropdown-toggle) {
        @apply min-w-[6.5rem];
    }
}

.app[data-app-sidebar-value="expanded"] {
    .form-controls {
        @apply ml-50;
    }
}
