.breadcrumb {
    @apply flex relative text-[0.85rem] py-2 mb-2;

    .breadcrumb-item {
        @apply flex font-medium whitespace-nowrap;

        &.active {
            @apply text-[#29363d];
        }

        & + .breadcrumb-item {
            @apply pl-2;

            &::before {
                @apply inline-block pr-2 content-["/"] text-[#536c79];
            }
        }
    }
}