.timeline {
    @apply ml-3;

    & .timeline-item {
        @apply relative pl-5 pb-8 border-gray-300 border-l-2 last:pb-0 last:border-l-transparent first:!border-gray-300;

        & .timeline-icon {
            @apply absolute top-px -left-[15px] bg-white p-1;

            & i {
                @apply text-[12px] w-5 h-5 flex justify-center items-center rounded text-white bg-gray-600;
            }
        }

        & .timeline-date {
            @apply text-xs text-gray-600;
        }

        & .timeline-title {
            @apply pt-1 font-semibold text-gray-700;
        }

        & .timeline-event {
            @apply mt-2;

            & .timeline-content {
                @apply inline-block rounded py-2 px-3 bg-gray-50 text-gray-700 text-xs;
            }
        }
    }

    & .timeline-primary {
        & .timeline-icon i {
            @apply bg-primary-600;
        }
    }

    & .timeline-success {
        & .timeline-icon i {
            @apply bg-green-500;
        }
    }

    & .timeline-danger {
        & .timeline-icon i {
            @apply bg-red-500;
        }
    }

    & .timeline-warning {
        & .timeline-icon i {
            @apply bg-amber-500;
        }
    }

    & .timeline-info {
        & .timeline-icon i {
            @apply bg-blue-600;
        }
    }

    & .timeline-cyan {
        & .timeline-icon i {
            @apply bg-cyan-500;
        }
    }

    & .timeline-indigo {
        & .timeline-icon i {
            @apply bg-indigo-500;
        }
    }

    & .timeline-purple {
        & .timeline-icon i {
            @apply bg-purple-500;
        }
    }
}