.answer-type {
    &.answer-type-text .badge {
        @apply bg-sky-300;
    }

    &.answer-type-number .badge {
        @apply bg-green-300;
    }

    &.answer-type-choice .badge {
        @apply bg-pink-300;
    }

    &.answer-type-bool .badge {
        @apply bg-violet-300;
    }

    &.answer-type-date .badge {
        @apply bg-orange-300;
    }

    &.answer-type-time .badge {
        @apply bg-yellow-300;
    }

    &.answer-type-range .badge {
        @apply bg-lime-300;
    }
}


.nav-questions {
    .nav-item {
        .nav-link {
            @apply flex items-center text-[0.8125rem] text-gray-700 hover:text-primary-800 h-13;

            &.answer-type {
                .badge {
                    transition: none;
                }

                &.active {
                    @apply bg-gray-100 text-gray-700;
                }

                .questions-item-text {
                    @apply max-h-9 overflow-hidden;
                }
            }
        }

        &.sortable-chosen {
            .nav-link {
                @apply !bg-gray-100 !text-gray-700 cursor-grab;
            }
        }

        &.sortable-ghost {
            @apply invisible;
        }
    }
}

.survey-logic-condition:not([data-form-deleted]):first-child,
.survey-logic-condition[data-form-deleted] + .survey-logic-condition:not([data-form-deleted]) {
    .survey-logic-join {
        @apply hidden;
    }
}

.survey-logic-condition:not([data-form-deleted]):first-child ~ .survey-logic-condition,  
.survey-logic-condition[data-form-deleted] + .survey-logic-condition:not([data-form-deleted]) ~ .survey-logic-condition {  /* these will reset all following visible back to original */
    .survey-logic-join {
        @apply block;
    }
}


.survey-results {
    @apply px-14 py-9 max-w-[48rem]
}

.survey-choice-result-bar {
    @apply relative my-1 mx-0 overflow-hidden bg-primary-600/10 rounded;

    &:hover {
        box-shadow: inset 0 0 100px rgba(0,0,0,0.05);
    }

    .survey-result-bar-filled {
        @apply absolute top-0 left-0 w-0 h-full bg-primary-600/20 pointer-events-none;
        
        transform: scaleX(0);
    }

    .survey-result-data {
        @apply flex justify-between items-center px-4 py-2;
    }
}

.survey-bool-answer-results {
    .survey-choice-result-bar {
        @apply bg-red-500/10;

        .survey-result-bar-filled {
            @apply bg-red-500/20;
        }
    }
}

.survey-range-result-bar-outer {
    @apply flex flex-col flex-grow basis-0 mx-2 my-0 first:ml-0 last:mr-0;
    
    .survey-range-result-bar {
        @apply relative flex justify-center rounded bg-blue-600/10 overflow-hidden h-36;

        &:hover {
            box-shadow: inset 0 0 100px rgba(0,0,0,0.05);
        }

        .survey-result-bar-filled {
            @apply absolute bottom-0 left-0 w-full h-0 bg-blue-600/20 pointer-events-none;
            
            transform: scaleY(0);
        }
    }
}

.survey-funnel {
    .row.header > div {
        @apply border-t-0 border-b-0 font-bold uppercase py-4 text-gray-600;
    }

    .survey-funnel-bar-outer {
        @apply relative;

        .survey-funnel-bar {
            @apply flex rounded overflow-hidden h-10 mx-auto my-0;

            transform: scaleX(0);

            &:hover {
                box-shadow: inset 0 0 100px rgba(0,0,0,0.05);
            }

            .survey-funnel-continued {
                @apply w-0 h-full bg-green-500/30;
            }

            .survey-funnel-dropoff {
                @apply h-full flex-grow bg-red-500/30;
            }
        }

        .survey-funnel-data {
            @apply absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center pointer-events-none;
        }
    }

    .percent {
        @apply opacity-60;
    }
}

.survey-funnel-appeared {
    .survey-funnel-bar {
        animation: 0.4s ease-out survey-choice-bar forwards;
    }
}

.survey-funnel-static {
    .survey-funnel-bar {
        animation: 0s linear survey-choice-bar forwards;
    }
}

.survey-question-appeared {
    .survey-choice-result-bar .survey-result-bar-filled {
        transform-origin: 0% 50%;
        animation: 0.4s ease-out survey-choice-bar forwards;
    }

    .survey-range-result-bar .survey-result-bar-filled {
        transform-origin: 50% 100%;
        animation: 0.4s ease-out survey-range-bar forwards;
    }
}

.survey-question-static {
    .survey-choice-result-bar .survey-result-bar-filled {
        transform-origin: 0% 50%;
        animation: 0s linear survey-choice-bar forwards;
    }

    .survey-range-result-bar .survey-result-bar-filled {
        transform-origin: 50% 100%;
        animation: 0s linear survey-choice-bar forwards;
    }
}

.survey-response-answer-choice {
    @apply rounded px-3 py-0.5 border text-black/60 mb-1.5 text-sm/normal;
}

.survey-response-bool {
    .survey-response-answer-choice {
        @apply bg-red-500/10 border-red-500/30;
    }
}

.survey-response-choice {
    .survey-response-answer-choice {
        @apply bg-primary-600/10 border-primary-600/30;
    }
}

.survey-print-report {
    @apply mx-auto my-0 p-[4vw] break-after-auto;

    .survey-print-header {
        @apply mb-15;
        
        h1 {
            @apply font-normal text-4xl;
        }
    }

    .survey-answer-results {
        @apply break-inside-avoid mt-15 mb-10;

        &:last-child {
            @apply mb-0 break-after-auto;
        }

        h6 {
            @apply text-sm;
        }
    }
}

@keyframes survey-choice-bar {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes survey-range-bar {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}