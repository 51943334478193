.colorpicker {
    @apply relative;
    --ifb-colorpicker-color: transparent;

    input {
        @apply !pl-10;
    }

    &:before,
    &:after {
        @apply block absolute w-6 h-5 left-2 top-1/2 -translate-y-1/2 content-[""] rounded-sm border border-gray-700 font-black;
        
        font-family: 'Font Awesome 5 Free';
    }

    &:before {
        background-image: repeating-conic-gradient(theme(colors.gray.100) 0 90deg, theme(colors.gray.300) 0 180deg);
        background-position: 0 0;
        background-size: 12px 50%;
        background-origin: border-box;
    }

    &:after {
        @apply bg-[--ifb-colorpicker-color];
    }

    &.invalid-color {
        

        &:before {
            @apply hidden;
        }

        &:after {
            @apply flex items-center justify-center content-["\f05e"] text-[13px]/none text-gray-700/60;
        }
    }
}