html, body {
    @apply overscroll-y-none;
}

body {
    @apply text-sm/normal antialiased text-gray-900 selection:bg-primary-600/40;
}

a {
    @apply text-primary-600 hover:text-primary-800 hover:underline;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-medium leading-[1.2];
}

h4 {
    @apply text-[1.3125rem];
}

h5 {
    @apply text-[1.1375rem];
}

h6 {
    @apply text-[1rem];
}

hr {
    @apply my-4 border-t border-black/10; 
}

.text-muted {
    @apply text-gray-600;
}

.col-header {
    @apply text-gray-600 uppercase text-[0.7rem] font-semibold mb-2;
}

#list-active-filters {
    @apply flex gap-2 mb-3 -mt-1;

    .badge {
        @apply font-normal cursor-pointer px-[0.5em] py-[0.4em];
    }
}

.page-toolbar {
    @apply flex gap-2;

    & > * {
        @apply mb-4;
    }
}

.list-toolbar {
    @apply flex justify-start md:justify-end gap-2;
}

