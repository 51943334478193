
/* #flatpickr-container {
    @apply 
        sm:max-md:fixed 
        sm:max-md:hidden
        sm:max-md:top-0
        sm:max-md:bottom-0
        sm:max-md:right-0
        sm:max-md:left-0
        sm:max-md:bg-black/50
        sm:max-md:z-[10000]
        sm:max-md:items-center
        sm:max-md:justify-center;
    
    .flatpickr-calendar {
        @apply 
            sm:max-md:!relative
            sm:max-md:!top-auto
            sm:max-md:!left-auto
            sm:max-md:!right-auto;
    }
} */


.flatpickr-calendar {
    @apply absolute hidden invisible w-61 max-h-0 p-2.5 opacity-0 bg-white rounded;

    box-shadow: 
        1px 0 0 theme(colors.gray.300), 
        -1px 0 0 theme(colors.gray.300),
        0 1px 0 theme(colors.gray.300),
        0 -1px 0 theme(colors.gray.300),
        0 3px 13px theme(colors.black / 8%);

    &.open, 
    &.inline {
        @apply visible opacity-100 max-h-[640px];
    }
      
    &.open {
        @apply inline-block z-[99999];
    }

    &.inline {
        @apply block relative mt-0;
    }

    .numInputWrapper {
        @apply relative h-auto;

        & input {
            @apply w-full p-0 border-0 rounded-[0.1875rem] focus:ring-0;
            
            appearance: textfield;
        }

        & span {
            @apply block p-0 cursor-pointer hover:text-primary-600 active:text-primary-600;

            &.arrowUp {
                @apply after:content-["\f0d8"];
            }

            &.arrowDown {
                @apply after:content-["\f0d7"];
            }

            &::after {
                @apply font-black text-[0.8rem] text-inherit text-center;
                
                font-family: 'Font Awesome 5 Free';
            }
        }

        &:hover span {
            @apply !opacity-100;
        }
    }

    .flatpickr-months {
        @apply relative overflow-hidden select-none text-center h-8 leading-none fill-gray-700 text-gray-700
               flex items-center;

        .flatpickr-prev-month,
        .flatpickr-next-month {
            @apply flex items-center justify-center w-5 h-8 cursor-pointer hover:text-primary-600;
        }

        .flatpickr-month {
            @apply mx-auto;
        }

        .flatpickr-current-month {
            @apply flex items-center gap-2 text-[120%]/none text-center;

            & span.cur-month {
                @apply font-semibold; 
            }

            .numInputWrapper {
                @apply w-14;

                & span {
                    @apply absolute right-0 w-4 h-1/2 opacity-0;
        
                    &.arrowUp {
                        @apply top-0 after:-top-px;
                    }
        
                    &.arrowDown {
                        @apply bottom-0 after:-bottom-px;
                    }
        
                    &::after {
                        @apply absolute left-0 right-0 font-black text-[0.8rem] text-inherit text-center;
                    }
                }
            }

            & input.cur-year {
                @apply inline-block h-auto cursor-default m-0 font-light bg-transparent pl-px focus:cursor-text;

                font-size: inherit;
                font-family: inherit;
                line-height: inherit;
                color: inherit;
            }
        }
    }

    .flatpickr-weekdays {
        @apply my-2.5;

        .flatpickr-weekdaycontainer {
            @apply grid grid-cols-7 font-medium text-[90%]/none text-gray-700;
        }
        
        .flatpickr-weekday {
            @apply w-7.5 flex items-center justify-center;
        }
    }

    .flatpickr-days {
        .dayContainer {
            @apply grid grid-cols-7 gap-0.5 justify-items-center items-center;
        }
        
        .flatpickr-day {
            @apply w-7.5 h-7.5 flex items-center justify-center cursor-pointer rounded-[0.1875rem] border border-transparent hover:bg-gray-100;
            
            &.prevMonthDay,
            &.nextMonthDay,
            &.flatpickr-disabled {
                @apply text-gray-200 pointer-events-none cursor-default;
            }
            
            &.today {
                @apply border-red-500 hover:bg-red-500 hover:text-white;
            }
        
            &.selected,
            &.today.selected {
                @apply border-primary-600 bg-primary-600 text-white;
            }
        } 
    }

    .flatpickr-time {
        @apply flex items-center;

        &:not(:first-child) {
            @apply -mx-2.5 px-2.5 border-t border-gray-100 pt-1 mt-1;
        }

        .numInputWrapper {
            @apply flex flex-col items-center flex-1;

            & input {
                @apply order-2 w-1/2 text-center p-0.5 my-1 text-sm/none border border-gray-200 rounded-[0.1875rem];
            }

            & span {
                @apply relative w-1/2 mx-auto leading-none rounded hover:bg-gray-100;

                &.arrowUp {
                    @apply after:content-["\f0d8"] order-1;
                }
    
                &.arrowDown {
                    @apply after:content-["\f0d7"] order-3;
                }

                &::after {
                    @apply w-full flex h-5.5 items-center justify-center text-lg/none;
                }
            }
        }
    }
}