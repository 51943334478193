.alert {
    @apply relative mb-4 border border-transparent rounded;

    & a {
        @apply text-inherit font-semibold;
    }

    .alert-body {
        @apply px-4 py-3;
    }

    &.alert-dismissible {
        .alert-body {
            @apply pr-0;
        }

        .close {
            @apply px-4 py-3 ml-2 opacity-50 text-[1.3125rem]/none font-bold hover:opacity-100 hover:text-black;
            text-shadow: 0 1px 0 #fff;

            & > span {
                @apply relative -top-px;
            }
        }
    }

    &.alert-success {
        @apply bg-green-100 text-green-700;
    }

    &.alert-warning {
        @apply bg-amber-100 text-amber-700;
    }

    &.alert-danger {
        @apply bg-red-100 text-red-700;
    }

    &.alert-info {
        @apply bg-blue-100 text-blue-700;
    }
}


#ui-messages {
    @apply fixed flex top-4 left-1/2 -translate-x-1/2 z-[1030];

    .alert {
        @apply flex justify-between items-start mb-2 opacity-0;
        
        transition: opacity 200ms ease-in;
        box-shadow: 0px 1px 4px rgba(0,0,0,0.1);

        &.visible {
            @apply opacity-100;
        }

        &.alert-dismissible .close {
            @apply px-5 py-4 
        }
    }
}

.form-errors .alert,
#ui-messages .alert {
    .alert-body {
        @apply pl-0 py-4;
    }

    &::before {
        @apply block text-base/normal font-black pl-4 pt-4 mr-2.5 relative -top-[0.03125rem];
        
        font-family: 'Font Awesome 5 Free';
    }

    &.alert-success::before {
        content: "\f058";
    }

    &.alert-info::before {
        content: "\f05a";
    }

    &.alert-warning::before {
        content: "\f071";
    }

    &.alert-danger::before {
        content: "\f06a";
    }
}
