.badge {
    @apply inline-flex items-center justify-center border border-transparent
        px-1 py-0.5 font-medium !leading-none whitespace-nowrap rounded text-[10px] no-underline hover:no-underline;

    transition: 
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &.badge-pill {
        @apply rounded-full px-[0.6rem];
    }

    &.badge-primary {
        @apply bg-primary-600 text-white;
    }

    &.badge-secondary {
        @apply bg-gray-300 text-gray-800;
    }

    &.badge-success {
        @apply bg-green-500 text-white;
    }

    &.badge-danger {
        @apply bg-red-500 text-white;
    }

    &.badge-info {
        @apply bg-blue-500 text-white hover:bg-blue-600;
    }

    &.badge-light {
        @apply bg-gray-100 text-gray-800;
    }

    &.badge-dark {
        @apply bg-gray-500 text-white;
    }

    &.badge-outline-secondary {
        @apply border-gray-400 text-gray-500;
    }

    &.badge-outline-success {
        @apply border-green-600 text-green-600;
    }
    
    &.badge-outline-danger {
        @apply border-red-500 text-red-500;
    }

    &.badge-outline-orange {
        @apply border-orange-600 text-orange-600;
    }

    &.badge-outline-light {
        @apply border-gray-100 text-gray-100;
    }
}