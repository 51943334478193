.chart {
    @apply relative;
}

.chart-basic:not([data-charts--basic-index-axis-value="y"]):not(.chart-pie) {
    @apply h-full min-h-[14rem] max-h-[28rem];
}

.chart-pie {
    @apply h-full;
}

.chart-trend {
    @apply h-80 max-h-80 mx-5;
}

.chart-nps {
    @apply h-30 max-h-30 px-7.5;
}

.chart-csat-widget {
    @apply h-full min-h-[14rem] max-h-[28rem];
}

.chart-legend {
    ul {
        @apply flex justify-center flex-nowrap gap-3;

       li {
            @apply flex items-center overflow-hidden text-ellipsis cursor-default text-[0.76562rem];

            span {
                @apply inline-block w-3 h-3 mr-1.5;

                &.line {
                    @apply h-[3px] w-[18px] mr-1;
                }
            }
        }
    }
}

.gauge {
    @apply relative h-[150px];

    &.gauge-nps {
        @apply h-35 -top-2;
    }
}