[data-controller="select"] {
    @apply inline-block;
    
    & button.form-control[data-select-target="toggle"] {
        @apply min-w-0 inline-flex items-center justify-start bg-white;
        
        &[disabled],
        &.disabled {
            @apply bg-gray-50;
        } 

        & [data-select-target="label"] {
            @apply whitespace-nowrap text-ellipsis overflow-hidden;
            
            & .placeholder {
                @apply italic text-gray-500 select-none;
            }
        }
    }

    & .dropdown-menu {
        @apply min-w-0 max-h-60;
        

        & .select-search {
            @apply bg-gray-50 p-3;

            & .form-control {
                @apply w-full pr-9 placeholder:italic;

                background-image: url("data:image/svg+xml,%3csvg fill='%231a2733' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e %3cpath fill-rule='evenodd' d='M11 3a8 8 0 1 0 4.906 14.32l3.387 3.387a1 1 0 0 0 1.414-1.414l-3.387-3.387A8 8 0 0 0 11 3Zm-6 8a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z' clip-rule='evenodd'/%3e %3c/svg%3e");
                background-position: calc(100% - 1rem) center;
                background-repeat: no-repeat;
                background-size: 1rem;
            }
        }

        & .dropdown-menu-panel{
            @apply h-full overflow-x-hidden overflow-y-auto;
        }

        & [data-select-target="item"] {
            @apply block w-full px-3 py-1.5 text-left text-sm leading-6;

            &.selected {
                @apply bg-primary-600/25;
            }

            &.hide {
                @apply hidden
            }

            /* &:focus,
            &:focus-visible {
                @apply outline-2 outline-dotted outline-offset-[-4px] outline-current;
            } */
        }

        /* &:focus-within {
            [data-select-target="item"].selected {
                @apply outline-2 outline-dotted outline-offset-[-4px] outline-current;
            }
        } */

        & .dropdown-group {
            & .dropdown-header {
                @apply px-3 py-1.5 text-sm text-left font-semibold leading-6 pointer-events-none;
            }

            & [data-select-target="item"] {
                @apply pl-6;
            }
        }

        &.select-multiple {
            & [data-select-target="item"] {
                &:before {
                    @apply inline-block text-gray-300 align-text-top content-["\f0c8"] text-base/4 font-normal mr-2;
                    font-family: 'Font Awesome 5 Free';
                }

                &.selected {
                    @apply bg-inherit hover:bg-primary-600/25;

                    &:before {
                        @apply text-primary-600 font-black content-["\f14a"];
                    }
                }
            }
        }

        &.show {
            @apply flex flex-col;
        }
    }
}

.form-row  {
    & [data-controller="select"] {
        @apply w-full;
        
        & [data-select-target="toggle"] {
            @apply w-full;
        }
    }
}

.form-sm [data-controller="select"],
.select-control-sm[data-controller="select"] {
    & .dropdown-menu {
        & [data-select-target="item"] {
            @apply text-[0.8rem] px-2 py-1;
        }
    }
}