.richtext-editor {
    @apply border border-gray-200 focus-within:border-primary-500 focus-within:ring-2 focus-within:ring-primary-50 rounded;

    & .richtext-toolbar {
        @apply invisible;

        &.ql-toolbar {
            @apply visible;
        }
    }
    & .ql-snow .ql-tooltip {
        @apply rounded p-2 shadow-none;

        & input[type="text"] {
            @apply border border-transparent text-gray-900 focus:ring-0 resize-none
                 border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-50 placeholder:text-gray-400 rounded px-3 py-1.5;
        }

        & .ql-action::after,
        & .ql-remove::before {
            @apply inline-block border-0 !px-3 ml-2 rounded hover:no-underline;
        }

        & .ql-action::after {
            @apply bg-primary-600 border-primary-600 text-white hover:bg-primary-700 hover:border-primary-700
                   active:bg-primary-700 active:border-primary-700;
        }

        & .ql-remove::before {
            @apply bg-red-500 border-red-500 text-white hover:bg-red-700 hover:border-red-700
                   active:bg-red-700 active:border-red-700;
        }
    }
    & .ql-container.ql-snow {
        @apply border-0 text-sm/[1.6];

        & a {
            @apply text-primary-600 hover:text-primary-800 underline;
        }

        & .ql-editor {
            @apply px-3 py-1.5 max-h-80 relative top-px leading-[1.6];

            &:focus-visible {
                outline: none;
            }
        }
    }

    &.richtext-editor-html {
        & .ql-container.ql-snow .ql-editor {
            @apply min-h-[5rem];
        }
    }

    & .ql-toolbar.ql-snow {
        @apply hidden bg-gray-50 border-0 px-2 py-1.5 flex gap-2 after:hidden rounded-t-[0.2rem];

        & .ql-formats {
            @apply inline-flex gap-1 mr-0 items-center opacity-50;
        }

        & button {
            @apply p-0 w-6 h-6 flex items-center justify-center rounded text-gray-500 hover:text-gray-900 focus:text-gray-900;

            border: solid 1px transparent;
            transition:
                color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out,
                opacity 0.15s ease-in-out;
            
            &[disabled] {
                @apply pointer-events-none opacity-50;
            }

            &.ql-active {
                @apply text-primary-600 hover:bg-transparent hover:border-primary-600;
            }

            & svg {
                @apply w-4 h-4 pointer-events-none;

                & .ql-stroke {
                    @apply stroke-current;
                }

                & .ql-fill {
                    @apply fill-current;
                }
            }
        }

        & .ql-picker {
            @apply text-[13px] text-gray-500;

            & .ql-picker-label {
                @apply rounded hover:text-gray-900 focus:text-gray-900 font-normal;

                &.ql-active {
                    @apply text-primary-600 hover:bg-transparent hover:border-primary-600 focus:bg-transparent focus:border-primary-600;
                }

                & .ql-stroke {
                    @apply stroke-current;
                }
            }

            & .ql-picker-options {
                @apply bg-white rounded shadow-none;

                & .ql-picker-item {
                    @apply hover:text-gray-900;

                    &.ql-selected {
                        @apply text-primary-600;
                    }
                }
            }

            &.ql-expanded {
                & .ql-picker-label {
                    @apply text-gray-900 border-gray-500;
                }

                & .ql-picker-options {
                    @apply border-gray-200 mt-2px;
                }
            }

            &.ql-button-picker {
                & .ql-picker-label {
                    @apply p-0 w-6 h-6 flex items-center justify-center;
                }

                & .ql-picker-item {
                    &[data-label]:not([data-label=""])::before {
                        content: attr(data-label);
                    }
                }

                svg {
                    @apply w-4 h-4 relative mt-0 top-0;
                }
            }
            
            &.ql-icon-picker {
                & .ql-picker-label {
                    @apply flex items-center justify-center;
                }

                svg {
                    @apply w-4 h-4;
                }
            }

            &:not(.ql-color-picker):not(.ql-icon-picker):not(.ql-button-picker) svg {
                @apply w-3.5 h-3.5;
                margin-top: -7px;
                right: 2px;
            }
        }

        & [data-richtexteditor-target="charsleft"] {
            @apply text-xs text-gray-500;
        }
    }

    /* &:focus-within { */
    &.richtext-focused {
        & .ql-toolbar.ql-snow {
            @apply flex;

            & .ql-formats {
                @apply opacity-100 transition-opacity;
            }
        }
    }

    & .ql-editor {
        & hr {
            @apply m-0;
        }

        &.ql-blank::before {
            @apply text-gray-500 left-3 right-3 not-italic;
        }

        & img {
            @apply inline;
        }

        & .text-size-small {
            font-size: 0.75rem;
        }
        
        & .text-size-large {
            font-size: 1.25rem;
        }

        & .text-size-huge {
            font-size: 1.8rem;
        }

        & .text-align-left {
            text-align: left;
        }

        & .text-align-right {
            text-align: right;
        }

        & .text-align-center {
            text-align: center;
        }

        & .text-align-justify {
            text-align: justify;
        }
    }
}

