.sidebar {
    @apply bg-gray-800 text-white text-sm will-change-transform;
    transition: width 0.25s;

    a {
        @apply text-inherit;
    }
}

.sidebar-nav {
    .sidebar-item {
        @apply w-13 overflow-hidden;
        transition: width 0.25s;
    }

    .sidebar-link {
        @apply block px-4 py-3 hover:bg-primary-600 text-sm leading-normal no-underline whitespace-nowrap;
        
        i {
            @apply inline-block opacity-50 w-5 ml-px mr-2 text-center;
            transform: scale(1.3);
            transition: transform 0.25s ease-out;
        }

        span {
            @apply inline-block opacity-0;
            transform: translateX(0.7rem);
            transition: opacity 0.25s ease 0.15s, transform 0.25s;
        }

        &.active {
            @apply bg-gray-600 hover:bg-primary-600;

            i {
                @apply text-primary-500 opacity-100 text-center;
            }
        }

        &:hover {
            i {
                @apply text-white opacity-100;
            }
        }
    }
}


.sidebar {
    &.sidebar-compact .sidebar-nav .sidebar-link:hover {
        span {
            @apply absolute opacity-100 bg-primary-950 px-2 py-[0.1rem] rounded pointer-events-none text-[90%] font-medium;
            
            transition: opacity 0.25s ease 1s;
        }
    }

    &.sidebar-expanded .sidebar-nav {
        .sidebar-item {
            @apply w-50;
        }
    
        .sidebar-link {
            i {
                transform: scale(1);
            }
    
            span {
                @apply opacity-100; 
                transform: none;
                transition: opacity 0.25s, transform 0.25s;
            }
        }
    }
}

[data-sticky-sidebar-target="sticky"] {
    @apply z-[1];
}
