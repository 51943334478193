.quick-stats {
    @apply grid gap-x-4 gap-y-6 my-2 px-5;
    grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));

    .quick-stats-item {
        @apply flex items-center leading-none basis-1/5;

        .quick-stats-icon {
            @apply text-muted text-2xl/none mx-4;
        }

        .quick-stats-title {
            @apply text-muted uppercase font-bold text-xs/none mb-1 truncate;
        }

        .quick-stats-stat {
            @apply text-muted flex items-center;

            .quick-stats-value {
                @apply relative text-primary-600 h-6.5 flex items-center;
            }
        }

        .quick-stats-scale {
            @apply absolute -bottom-3 left-0 right-0 text-center text-gray-400 whitespace-nowrap text-[0.68rem];
        }
    }
}