.pagination {
    @apply flex mb-4;

    .page-item:first-child .page-link {
        @apply rounded-l;
    }
    
    .page-item:last-child .page-link {
        @apply rounded-r;
    }

    .page-link,
    & li a, {
        @apply relative block px-3 py-2 -ml-px leading-tight bg-white text-primary-600 border border-gray-300;
        @apply hover:bg-gray-200 hover:text-primary-800 hover:z-[2];
    }

    .page-item.active .page-link,
    & li.active a {
        @apply bg-primary-600 text-white border-primary-600 pointer-events-none cursor-default z-[3];
    }

    .page-item.disabled .page-link,
    & li.disabled a {
        @apply bg-white text-gray-400 pointer-events-none cursor-default;
    }
    
    &.pagination-sm {
        .page-item:first-child .page-link {
            @apply rounded-l-[0.1875rem];
        }

        .page-item:last-child .page-link {
            @apply rounded-r-[0.1875rem];
        } 
    
        .page-link,
        & li a, {
            @apply px-2 py-1 leading-normal text-[0.76562rem];
        }
    }
}
