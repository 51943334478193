.btn {
    @apply relative inline-block text-center align-middle font-medium text-sm leading-[1.6] border border-transparent
           px-6 py-1.5 rounded hover:no-underline;

    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        opacity 0.15s ease-in-out;

    &.btn-sm {
        @apply px-2 py-1 text-[0.76562rem] rounded-[0.1875rem] leading-normal;
    }

    &.btn-xs {
        @apply px-[0.2rem] py-[0.1rem] text-[0.76562rem] leading-normal rounded-[0.1875rem];
    }

    &.btn-primary {
        @apply bg-primary-600 border-primary-600 text-white hover:bg-primary-700 hover:border-primary-700
               active:bg-primary-700 active:border-primary-700;
    }

    &.btn-secondary {
        @apply bg-white border-gray-200 text-gray-700 hover:bg-gray-300 hover:border-gray-300
               active:bg-gray-300 active:border-gray-300 focus-within:bg-gray-300 focus-within:border-gray-300;
    }

    &.btn-success {
        @apply bg-green-500 border-green-500 text-white hover:bg-green-600 hover:border-green-600
               active:bg-green-600 active:border-green-600;
    }

    &.btn-danger {
        @apply bg-red-500 border-red-500 text-white hover:bg-red-700 hover:border-red-700
               active:bg-red-700 active:border-red-700;
    }
    
    &.btn-info {
        @apply bg-blue-600 border-blue-600 text-white hover:bg-blue-700 hover:border-blue-700
               active:bg-blue-700 active:border-blue-700;
    }

    &.btn-indigo {
        @apply bg-indigo-500 border-indigo-500 text-white hover:bg-indigo-700 hover:border-indigo-700
               active:bg-indigo-700 active:border-indigo-700;
    }

    &.btn-orange {
        @apply bg-orange-500 border-orange-500 text-white hover:bg-orange-700 hover:border-orange-700
               active:bg-orange-700 active:border-orange-700;
    }
    
    &.btn-outline-primary {
        @apply border-primary-600 text-primary-600 hover:bg-primary-600 hover:border-primary-600 hover:text-white;
    }

    &.btn-outline-secondary {
        @apply border-gray-300 text-gray-400 hover:bg-gray-400 hover:text-gray-800 hover:border-gray-400;
    }

    &.btn-outline-success {
        @apply border-green-600 text-green-600 hover:bg-green-500 hover:border-green-500 hover:text-white;
    }

    &.btn-outline-danger {
        @apply border-red-600 text-red-600 hover:bg-red-500 hover:border-red-500 hover:text-white;
    }

    &.btn-outline-info {
        @apply border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white;
    }

    &.btn-outline-indigo {
        @apply border-indigo-600 text-indigo-600 hover:bg-indigo-600 hover:text-white;
    }

    &.btn-outline-orange {
        @apply border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white;
    }

    &.btn-icon {
        @apply inline-flex items-center justify-center border-0 leading-none p-[0.15rem] aspect-square;
    }

    &:disabled,
    &.disabled {
        @apply opacity-50 cursor-default pointer-events-none;
    }
}

.btn-group {
    @apply relative inline-flex align-middle;

    & > .btn {
        @apply relative flex-auto;
        &:not(:first-child) {
            @apply rounded-l-none -ml-px;
        }

        &:not(:last-child):not(.dropdown-toggle) {
            @apply rounded-r-none;
        }
    }
}

.btn-group-sm {
    .btn {
        @apply px-2 py-1 text-[0.76562rem] leading-normal rounded-[0.1875rem];
    }
}